<script>
export default {
  metaInfo() {
    return {
      title: 'JaxWs - Developers - Europe PMC',
    }
  },
}
</script>
<template>
  <div id="jaxWs-soap-page">
    <div class="grid-row">
      <div class="col-11">
        <a id="top"></a>
        <h1>Using the Europe PMC SOAP web service with Java in JAX-WS 2.1</h1>
        <p>The user must first have installed JDK, version 5 or higher.</p>
        <h2>Building the client</h2>
        <p>
          A client program must be created to access the service. The user can
          choose to use or adapt the example client provided in the following
          steps:
        </p>
        <div>
          <p>
            <a href="#generateportableartefacts"
              >1. Generate portable artefacts required to compile the client</a
            >
          </p>
          <p>
            <a href="#writetheclient">2. Write the client</a>
          </p>
          <p>
            <a href="#compiletheclient">3. Compile the client</a>
          </p>
          <p>
            <a href="#runtheclient">4. Run the client</a>
          </p>
        </div>
        <h3 id="generateportableartefacts">
          Generate portable artefacts required to compile the client
        </h3>

        <p>
          You will use the
          <a
            href="http://docs.oracle.com/javase/6/docs/technotes/tools/share/wsimport.html"
            target="_blank"
            ><strong>wsimport</strong></a
          >
          tool (install separately if not included in JDK) to generate the
          client side artefacts.
        </p>
        <p>
          Assuming you are working in a directory "wsclient", to generate all
          the portable artefacts for the client, execute the following wsimport
          command:
        </p>

        <pre>
wsclient&gt; %JAVAPATH%\wsimport.exe -p ebi.ws.client \
-keep -target 2.1 https://www.ebi.ac.uk/europepmc/webservices/soap?wsdl
</pre
        >

        <p>
          This generates the artefacts in the ebi\ws\client directory. Note:
          Omitting the p or package name will generate the package in
          uk\ac\ebi\cdb\webservice.
        </p>
        <p class="backtotop">
          <a href="#top" class="top_of_page">Back to top</a>
        </p>

        <h3 id="writetheclient">Write the client</h3>

        <p>
          Write the client or use/adapt the following WSCitationImplPortClient
          example client program.
        </p>
        <p>
          Refer to the
          <a
            href="/doc/EBI_Europe_PMC_Web_Service_Reference.pdf"
            target="_blank"
            >Web Services Reference Guide<img
              src="@/assets/pdficon_small.png"
              alt="PDF icon"
          /></a>
          for the full details of the available methods and their
          inputs/outputs.
        </p>
        <p>
          The example client shown below invokes the following methods and
          parameters:
        </p>
        <div class="table-overflow">
          <table class="dark">
            <tbody>
              <tr>
                <th>Method</th>
                <th>Parameters in Example Client</th>
              </tr>

              <tr>
                <td>listSearchFields</td>
                <td>email = testclient@ebi.ac.uk</td>
              </tr>

              <tr>
                <td>searchPublications</td>
                <td>
                  queryString = HAS_UNIPROT:y HAS_FULLTEXT:y HAS_REFLIST:y
                  HAS_XREFS:y<br />sort = cited desc<br />
                  cursorMark = *<br />resultType = lite<br />synonym = false<br />email
                  = testclient@ebi.ac.uk
                </td>
              </tr>

              <tr>
                <td>getDatabaseLinks</td>
                <td>
                  id = for each record in searchPublications<br />
                  source = for each record in searchPublications<br />
                  database = UNIPROT<br />
                  offset = 0<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>

              <tr>
                <td>getCitations</td>
                <td>
                  id = for each record in searchPublications<br />
                  source = for each record in searchPublications<br />
                  offset = 0<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>

              <tr>
                <td>getReferences</td>
                <td>
                  id = for each record in searchPublications<br />
                  source = for each record in searchPublications<br />
                  offset = *<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>
              <tr>
                <td>profilePublications</td>
                <td>
                  queryString = HAS_UNIPROT:y HAS_FULLTEXT:y HAS_REFLIST:y
                  HAS_XREFS:y<br />sort = cited desc<br />
                  profileType = pub_type<br />
                  synonym = false<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>

              <tr>
                <td>getFullTextXML</td>
                <td>
                  id = PMC3280045<br />
                  source = pmc<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>

              <tr>
                <td>getSupplementaryFiles</td>
                <td>
                  id = PMC3253803<br />
                  source = pmc<br />
                  email = testclient@ebi.ac.uk
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          You can find an example client
          <a href="/doc/WSCitationImplPortClient.java" target="_blank">here</a>
        </p>

        <p>
          Classes in the package ebi\ws\client are from the portable artefacts
          generated in the previous step. WSCitationImplService is the portable
          artefact for the service implementation. WSCitationImpl is the Java
          interface for the service endpoint generated from the WSDL. The WSDL
          endpoint is defined inside the WSCitationImplService class. After the
          client retrieves the endpoint, it invokes the operation required.
        </p>

        <p class="backtotop">
          <a href="#top" class="top_of_page">Back to top</a>
        </p>
        <h3 id="compiletheclient">Compile the client</h3>

        <p>
          The next step is to compile the client classes. The following command
          compiles WSCitationImplPortClient and writes the class file to the
          current directory.
        </p>

        <pre>
wsclient&gt; %JAVAPATH%\javac.exe WSCitationImplPortClient.java
</pre
        >
        <p class="backtotop">
          <a href="#top" class="top_of_page">Back to top</a>
        </p>

        <h3 id="runtheclient">Run the client</h3>
        <p>
          From build/classes/client directory, execute the following command
        </p>

        <pre>
wsclient&gt; %JAVAPATH%\java.exe WSCitationImplPortClient.java
</pre
        >

        <p class="backtotop">
          <a href="#top" class="top_of_page">Back to top</a>
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#jaxWs-soap-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .backtotop {
    text-align: center;
    font-size: 0.9em;
  }
  .table-overflow {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
</style>
